// components/search/results/DefinitionResult.vue
<template>
  <v-list-item>
    <v-list-item-content>
      <!-- Title Section -->
      <v-list-item-title class="result-title d-flex align-center">
        <router-link :to="getUrl(item)" class="text-decoration-none" target="_blank">
          <!-- Show highlighted term if available, otherwise fallback to regular term -->
          <span v-if="item.highlights?.term" v-html="item.highlights.term"></span>
          <span v-else>{{ item.definition.term }}</span>
        </router-link>
        
        <!-- Score and Default chips -->
        <v-chip
          x-small
          outlined
          class="score-chip ml-2"
          color="primary"
          title="Relevance Score"
        >
          {{ (item.score || 0).toFixed(2) }}
        </v-chip>
        <v-chip
          v-if="item.explanation.isdefault"
          x-small
          outlined
          color="success"
          class="metadata-chip ml-2"
        >
          Default
        </v-chip>
      </v-list-item-title>

      <!-- Highlights Section -->
      <v-list-item-subtitle v-if="hasHighlights" class="result-highlights">
        <!-- Definition text highlight -->
        <div v-if="item.highlights?.definition" class="highlight-text">
          <v-icon x-small class="mr-1">mdi-text</v-icon>
          <span v-html="item.highlights.definition"></span>
        </div>

        <!-- Term lipi highlight -->
        <div v-if="item.highlights?.termlipi" class="highlight-text">
          <v-icon x-small class="mr-1">mdi-translate</v-icon>
          <span v-html="item.highlights.termlipi"></span>
        </div>

        <!-- Definition lipi highlight -->
        <div v-if="item.highlights?.definitionlipi" class="highlight-text">
          <v-icon x-small class="mr-1">mdi-text-box</v-icon>
          <span v-html="item.highlights.definitionlipi"></span>
        </div>
      </v-list-item-subtitle>

      <!-- Definition Text Section -->
      <div v-if="showDefinitionText" class="mt-2">
        <div class="definition-text">
          {{ item.definition_text }}
        </div>
      </div>

      <!-- Metadata Section -->
      <div v-if="showTermLipi || item.explanation.notes" class="result-metadata mt-1 d-flex align-center">
        
        <template v-if="showTermLipi">
          <span class="lipi-text">{{ item.definition.termlipi }}</span>
        </template>

        <template v-if="item.explanation.notes">
          <v-divider vertical class="mx-2"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                x-small
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>{{ item.explanation.notes }}</span>
          </v-tooltip>
        </template>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DefinitionResult',
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    hasHighlights() {
      return this.item.highlights && Object.keys(this.item.highlights).length > 0
    },
    showDefinitionText() {
      return this.item.definition_text && !this.item.highlights?.definition
    },
    showTermLipi() {
      return this.item.definition.termlipi && !this.item.highlights?.termlipi
    }
  },
  methods: {
    getUrl(item) {
      return `/app/definition/${item.definition.definitionid}`
    }
  }
}
</script>

<style scoped>
.result-title {
  line-height: 1.4;
}

.score-chip {
  font-size: 0.75rem;
}

.highlight-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.4;
}

.highlight-text :deep(mark) {
  background-color: rgba(255, 213, 79, 0.4);
  padding: 2px 0;
}

.definition-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.5;
}

.metadata-chip {
  font-size: 0.75rem;
}

.lipi-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
}
</style>