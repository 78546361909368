<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="primary"
        dark
        small
        v-bind="attrs"
        v-on="on"
        class="pl-1 pr-1"
      >
        mdi-tune
      </v-icon>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <ReadingHelper :compact="true"/>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <div 
            v-if="supportedLanguages && supportedLanguages.length < 1 || !supportedLanguages" 
            class="text-body-1 error--text text--lighten-2 my-1"
          >
            No document translations
          </div>
          <LanguageSelector v-else />
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <ReadingLayout :compact="true"/>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <DebugSetting v-if="isManager(document.documenttypeid.name)" :compact="true"/>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <LiveEditing v-if="isManager(document.documenttypeid.name)" :compact="true"/>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

</template>
<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import ReadingHelper from '@/components/settings/ReadingHelper'
import ReadingLayout from '@/components/settings/ReadingLayout'
import DebugSetting from '@/components/settings/DebugSetting'
import LanguageSelector from '@/components/common/LanguageSelector'
import LiveEditing from '@/components/settings/LiveEditing.vue'

export default {
  name: 'Toolbar',
  components: {
    ReadingHelper,
    ReadingLayout,
    LanguageSelector,
    DebugSetting,
    LiveEditing
},
  props: {},
  data: () => ({}),
  computed: {
    ...mapState('documents', ['document', 'supportedLanguages', 'selectedLanguages']),
    ...mapGetters('account', ['isManager'])
  }
}
</script>