// components/search/results/DocumentResult.vue
<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="result-title d-flex align-center">
        <router-link :to="getUrl(item)" target="_blank" class="text-decoration-none">
          {{ item.title || 'Untitled' }}
        </router-link>
        <v-chip
          x-small
          outlined
          class="score-chip ml-2"
          color="primary"
          title="Relevance Score"
        >
          {{ (item.score || 0).toFixed(2) }}
        </v-chip>
      </v-list-item-title>

      <v-list-item-subtitle v-if="item.highlights" class="result-highlights">
        <template v-for="(highlight, field) in item.highlights">
          <div :key="field" class="highlight-text mt-1" v-html="highlight"></div>
        </template>
      </v-list-item-subtitle>

      <Breadcrumb :document-item="item.documentitemid" :document="item.documentitemid.documentid" class="highlight-text mt-1"/>

      <!-- <div class="result-metadata">
        <v-chip v-if="item.documentitemid.documentid?.documenttypeid?.name" x-small outlined class="metadata-chip">
          {{ item.documentitemid.documentid.documenttypeid.name }}
        </v-chip>
        <v-chip v-if="item.documentitemid.languageid?.english_name" x-small outlined class="metadata-chip">
          {{ item.documentitemid.languageid.english_name }}
        </v-chip>
        <span v-if="item.documentitemid.documentid?.englishtitle" class="document-title">
          {{ item.documentitemid.documentid.englishtitle }}
        </span>
      </div> -->
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Breadcrumb from '@/components/documentItems/Breadcrumb'

export default {
  name: 'DocumentResult',
  components: { Breadcrumb },
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  methods: {
    getUrl(item) {
      if (item.documentitemid?.breadcrumb) {
        const breadcrumb = JSON.parse(item.documentitemid.breadcrumb)
        const leafNode = breadcrumb.find(bi => bi.isleafnode)
        const lastItem = breadcrumb.slice(-1).pop()
        return `/app/document/${item.documentitemid.documentid?.documentid}/documentitem/${leafNode?.id}?documentitem_focus=${lastItem?.id}`
      }
      return null
    }
  }
}
</script>
<style scoped>
.result-title {
  line-height: 1.4;
}

.score-chip {
  font-size: 0.75rem;
}

.highlight-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.4;
}

.highlight-text :deep(mark) {
  background-color: rgba(255, 213, 79, 0.4);
  padding: 2px 0;
}

.metadata-chip {
  font-size: 0.75rem;
}

.lipi-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
}
</style>