// plugins/confirm.js
import Vue from 'vue'
import Confirm from '@/components/common/Confirm.vue'

const ConfirmPlugin = {
  install(Vue, { vuetify }) {
    if (!vuetify) {
      console.error('Vuetify is not initialized')
      
      return
    }

    const ConfirmConstructor = Vue.extend(Confirm)
    const confirmInstance = new ConfirmConstructor({
      vuetify
    })
    
    const container = document.createElement('div')

    document.body.appendChild(container)
    confirmInstance.$mount(container)

    Vue.prototype.$confirm = (message, options) => {
      return confirmInstance.open(message, options)
    }
  }
}

export default ConfirmPlugin