<template>

  <v-row v-if="allowEdit(document.documenttypeid.name)" no-gutters>
    <v-col>
      <v-alert
        v-if="error.show"
        icon="mdi-alert-circle"
        dismissible
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>

      <v-expansion-panels tile flat multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row no-gutters align="center">
              <v-col>
                <span><strong>Document: </strong>{{ document.englishtitle }}</span>
              </v-col>
              <v-col cols="auto">
                <v-chip :color="document.ispublic ? 'success' : 'warning'" small class="ml-2">
                  {{ document.ispublic ? 'Public' : 'Private' }}
                </v-chip>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <image-manager
              v-if="isImageEnabled() && (document && Object.keys(document).length)"
              :id="document.documentid"
              :connection-type-id="connectionType.documents"
              :allow-upload="true"
            ></image-manager>
            <document-form 
              v-if="document && Object.keys(document).length" 
              :document="document" 
              :save="saveDocument"
            ></document-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <span v-if="documentAuthors.length">
              <strong>Authors: </strong>
              <span v-for="(a, i) in documentAuthors" :key="a.documentauthorid">
                <router-link :to="{ name: 'person_home', params: { personid: a.personid.personid }}">{{ a.personid.englishname }}</router-link>
                <span v-if="i+1 < documentAuthors.length">, </span>
              </span>
            </span>
            <span v-else><strong>Add Author</strong></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DocumentAuthorsForm 
              :document-authors="documentAuthors" 
              :save="saveAuthors"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="allowedTermTypes()">
          <v-expansion-panel-header>
            <span v-if="Object.keys(document.terms).length">
              <strong>Terms: </strong>
              <TermAssigned :data="document" class="mt-1" />
            </span>
            <span v-else>
              <strong>Add Terms</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="space-around" no-gutters>
              <v-col v-for="termType in allowedTermTypes()" :key="`att-${termType}`">
                <TermsField 
                  :term-type="termType" 
                  :source-id="document.documentid" 
                  :source-connection-type-id="connectionType.documents"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Translations ({{ documentTranslations ? documentTranslations.length : 0 }})</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <document-translation-listing 
              v-if="documentTranslations"
              :key="`dt-${JSON.stringify(documentTranslations).length}`"
              view="listing" 
            ></document-translation-listing>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-col>
    <!-- <v-col cols="4" sm="12" md="4" lg="3" xl="3" class="pl-2">
      <v-card tile>
        <v-card-title>Information</v-card-title>
      </v-card>
    </v-col> -->
  </v-row>
  <v-alert v-else prominent type="error">
    Access is denied, you do not have the required groups to access this, your group: {{ groups }}
  </v-alert>
</template>
<script>
// document-item-manage
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import DocumentAuthorsForm from '@/components/documents/forms/DocumentAuthorsForm'
import DocumentForm from '@/components/documents/forms/DocumentForm'
import DocumentTranslationListing from '@/components/documents/DocumentTranslationListing'
import TermsField from '@/components/terms/TermsField'
import utils from '@/helpers/utils'
import TermAssigned from '@/components/terms/TermAssigned'
import DocAdmin from '@/store/data'

export default {
  name: 'DocumentEditorPage',
  components: {
    DocumentForm,
    DocumentTranslationListing,
    DocumentAuthorsForm,
    TermsField,
    TermAssigned
  },
  data: () => ({
    loading: true,
    connectionType: configs.connectionProperties.types,
    documentItemId: null,
    showError: false,
    errorMessage: '',
    error: {
      show: false,
      message: null
    },
    utils
  }),
  computed: {
    ...mapState('documents', ['document', 'dataStructure', 'supportedLanguages', 'documentAuthors', 'documentTranslations']),
    ...mapState('languages', ['languages']),
    ...mapState('app', ['debug']),
    ...mapState('account', ['groups']),
    ...mapGetters('account', ['allowEdit']),
    ...mapGetters('documentItems', ['getDocumentItemsByParent', 'isMoreInfoPaneOpen', 'getDocumentItemTranslations', 'getDocumentItemsById']),
    ...mapGetters('documents', ['isImageEnabled', 'allowedTermTypes']),
    ...mapGetters('languages', ['languageShortCodeById']),
    panel: {
      set(ep) {
        this.$router.replace({ query: { ...this.$route.query, ep } })
      },
      get() { return this.$route.query.ep }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async saveDocument(document) {
      
      if ('documenttypeid' in document) {
        document.documenttypeid_id = document.documenttypeid.documenttypeid
      }

      if ('datastructureid' in document && document.datastructureid) {
        document.datastructureid_id = document.datastructureid.datastructureid
      }

      await this.$store.dispatch('documents/updateDocument', document)
      this.$store.dispatch('app/setSnackBar', 'Document updated.')
      this.$router.push({ 
        name: 'document_home', 
        params: {
          documentid: document.documentid
        }
      })
    },
    saveAuthors(documentAuthors) {
      // delete all items first
      this.documentAuthors.forEach((ca) => {
        this.$store.dispatch('documents/deleteDocumentAuthors', ca.documentauthorid)
      })
      // repopulate
      documentAuthors.forEach((da) => {
        this.$store.dispatch('documents/createDocumentAuthors', da)
      })

      this.$store.dispatch('app/setSnackBar', 'Authors updated.')
    },
    saveTranslations(documentTranslations) {
      console.log(`save them translations=${JSON.stringify(documentTranslations)}`)
      this.$store.dispatch('app/setSnackBar', 'Document Translations updated.')
    },
    async loadData() {
      if (!this.document) {
        await this.$store.dispatch('documents/getDocument', this.$route.params.documentid)
        console.log(`DocumentEditPage.loadData(documents/getDocument, ${this.$route.params.documentid})`)
        this.loading = false
      }
      if (!this.documentAuthors) {
        await this.$store.dispatch('documents/getDocumentAuthors', this.$route.params.documentid)
        console.log(`DocumentEditPage.loadData(documents/getDocumentAuthors, ${this.$route.params.documentid})`)
        this.loading = false
      }
    }
  }

}

</script>