<!-- SearchPage.vue -->
<template>
  <div class="search-page">
    <div class="search-section">
      <v-container class="px-4 pb-0">
        <v-row justify="center">
          <v-col cols="12">
            <SearchTypeSelector @change="onSearchTypeChange" />
          </v-col>
        </v-row>
      </v-container>
      <SearchBar 
        ref="searchbar" 
        :loading="searching" 
        :debug-data="response?.debug"
        @search="onSearchBarSearch"
      />
    </div>
    <div v-if="hasActiveFilters" class="active-filters-section">
      <v-container class="py-2">
        <div class="d-flex align-center flex-wrap">
          <span class="active-filters-label mr-2">Active filters:</span>
          
          <v-chip
            v-for="[key, value] in facets"
            :key="`${key}-${value}`"
            small
            class="mr-2 mb-1"
            close
            @click:close="removeFilter(key, value)"
          >
            {{ formatFilterLabel(key) }}: {{ decodeURIComponent(value) }}
          </v-chip>
          
          <v-btn
            x-small
            text
            color="primary"
            class="ml-auto"
            @click="clearAllFilters"
          >
            Clear all filters
          </v-btn>
        </div>
      </v-container>
    </div>
    <!-- Main Content -->
    <v-container class="search-container" fluid>
      <v-row no-gutters>
        <!-- Filters Column - Only show if facets exist -->
        <v-col 
          v-if="hasFacets"
          cols="12" 
          md="3" 
          class="pr-md-4"
        >
          <Filters 
            :facets="processFacets" 
            :initial-selected="facets"
            @change="onFilterChange" 
          />
        </v-col>

        <!-- Results Column - Adjust width based on facets -->
        <v-col 
          cols="12" 
          :md="hasFacets ? 9 : 12"
          :class="{ 'px-md-4': hasFacets }"
        >
          <div v-if="isLoading" class="d-flex justify-center align-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else-if="response" class="results-container">
            <!-- Results Header -->
            <div class="results-header">
              <span class="results-count">
                {{ response.count ? `${1 + pageOffset} - ${pageOffset + response.results.length} of ${response.count} results` : 'No results' }}
                <template v-if="query">
                  for "<span class="query-text">{{ query }}</span>"
                </template>
              </span>
              <span v-if="response.took" class="response-time">
                ({{ response.took }}ms)
              </span>
            </div>

            <!-- Results List -->
            <v-card
              v-if="response.count"
              flat
              class="results-card"
            >
              <v-list three-line class="results-list">
                <template v-for="(item, index) in response.results">
                  <component 
                    :is="resultComponent"
                    :item="item"
                    :key="item.id"
                  />
                  <v-divider 
                    v-if="index < response.results.length - 1" 
                    :key="`div-${item.id}`"
                    class="result-divider"
                  />
                </template>
              </v-list>

              <!-- Pagination -->
              <div class="pagination-container">
                <v-pagination
                  v-if="response.count > pageSize"
                  :value="parseInt(currentPage)"
                  :length="totalPages"
                  :total-visible="7"
                  @input="handlePageChange"
                  class="search-pagination"
                ></v-pagination>
              </div>
            </v-card>

            <!-- No Results -->
            <v-card v-else flat class="no-results-card">
              <SearchHelp :no-results="true" />
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchBar from '@/components/search/SearchBar'
import SearchHelp from '@/components/search/SearchHelp'
import Filters from '@/components/search/Filters'
import SearchTypeSelector from '@/components/search/SearchTypeSelector'
import Breadcrumb from '@/components/documentItems/Breadcrumb'
import DocumentResult from '@/components/search/results/DocumentResult'
import PersonResult from '@/components/search/results/PersonResult'
import DefinitionResult from '@/components/search/results/DefinitionResult'

import { debounce } from 'lodash'

export default {
  name: 'SearchPage',
  components: {
    SearchBar,
    SearchHelp,
    Filters,
    Breadcrumb,
    SearchTypeSelector,
    DocumentResult,
    PersonResult,
    DefinitionResult
  },
  data: () => ({
    query: null,
    currentPage: 1,
    pageSize: 20,
    searching: false,
    facets: [],
    respondToRouteChanges: true,
    isInternalUpdate: false,
    isLoading: false
  }),
  computed: {
    ...mapState('search', { 
      response: 'searchResults', 
      queryParams: 'searchQueryParams',
      currentSearchType: 'currentSearchType'
    }),
    
    processFacets() {
      console.log('Current search type:', this.currentSearchType) // Debug
      console.log('Current facets:', this.response?.facets) // Debug
      
      if (!this.response?.facets) return {}
      
      switch(this.currentSearchType) {
        case 'persons':
          return {
            languages: [...(this.response.facets.languages || [])]
          }
        case 'definitions':
          return {
            languages: [...(this.response.facets.languages || [])],
            default_status: [...(this.response.facets.default_status || [])]
          }
        default: // documents
          return {
            document_types: [...(this.response.facets.document_types || [])],
            document_item_types: [...(this.response.facets.document_item_types || [])],
            documents: [...(this.response.facets.documents || [])],
            languages: [...(this.response.facets.languages || [])]
          }
      }
    },
    hasActiveFilters() {
      return this.facets.length > 0
    },
    
    totalPages() {
      return this.response ? Math.ceil(this.response.count / this.pageSize) : 0
    },

    pageOffset() {
      return this.pageSize * (this.currentPage - 1)
    },

    resultComponent() {
      const components = {
        documents: 'DocumentResult',
        persons: 'PersonResult',
        definitions: 'DefinitionResult'
      }

      return components[this.currentSearchType] || 'DocumentResult'
    },
    hasFacets() {
      return this.response?.facets && 
            Object.keys(this.processFacets).length > 0 &&
            ['documents'].includes(this.currentSearchType)
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(newRoute, oldRoute) {
        // Skip if this is an internal update
        if (this.isInternalUpdate) {
          return
        }
        
        // Only handle actual query changes
        if (oldRoute && this.isQueryEqual(newRoute.query, oldRoute.query)) {
          return
        }

        const gotData = await this.loadStateFromQueryString()

        // Only search if we have a query or filters
        if (this.query || this.facets.length > 0) {
          await this.doSearch()
        }
      }
    }
  },
  methods: {
    async handlePageChange(newPage) {      
      // Convert to numbers for comparison
      const newPageNum = parseInt(newPage)
      const currentPageNum = parseInt(this.currentPage)
      
      if (newPageNum === currentPageNum) {
        return
      }

      // Update the current page
      this.currentPage = newPageNum

      // Prepare URL parameters
      const urlParams = {
        ...this.$route.query,
        page: newPageNum.toString()
      }

      // Update URL first
      this.updateUrl(urlParams)

      // Then perform the search
      await this.doSearch({
        query: this.query,
        page: newPageNum,
        pageSize: this.pageSize,
        filters: this.facets,
        type: this.$route.query.type || 'default',
        language: this.$route.query.language
      })
    },

    formatFilterLabel(key) {
      const labels = {
        'doc_type': 'Document Type',
        'doc_item_type': 'Item Type',
        'document': 'Document',
        'language': 'Language',
        'is_default': 'Default Status'  // Add this for definitions
      }

      return labels[key] || key
    },
    
    removeFilter(key, value) {
      // Remove the specific filter
      this.facets = this.facets.filter(([k, v]) => !(k === key && v === value))
      
      // Prepare URL parameters with all current state
      const urlParams = {
        ...this.$route.query,  // Keep all existing query params
        page: '1'  // Reset to page 1 when removing filter
      }

      // Remove the specific filter key if no more values for it exist
      if (!this.facets.some(([k]) => k === key)) {
        delete urlParams[key]
      } else {
        // Update the remaining values for this filter key
        const remainingValues = this.facets
          .filter(([k]) => k === key)
          .map(([, v]) => v)
        
        urlParams[key] = remainingValues.length === 1 ? remainingValues[0] : remainingValues
      }

      // Update URL and trigger new search
      this.updateUrl(urlParams)
      this.doSearch()
    },
    
    clearAllFilters() {
      this.facets = []
      
      // Preserve search query, type, and other non-filter parameters
      const urlParams = {
        ...this.$route.query,  // Start with current query params
        page: '1'  // Reset to page 1 when clearing filters
      }

      // Remove all filter keys but keep search-related params
      const preserveKeys = ['q', 'type', 'search_type', 'language', 'page']
      
      Object.keys(urlParams).forEach((key) => {
        if (!preserveKeys.includes(key)) {
          delete urlParams[key]
        }
      })

      // Update URL and trigger new search
      this.updateUrl(urlParams)
      this.doSearch()
    },

    updateUrl(newParams = {}) {      
      // Set internal update flag
      this.isInternalUpdate = true
      
      const updatedQuery = {}

      Object.entries(newParams).forEach(([key, value]) => {
        if (value != null && value !== '') {
          if (Array.isArray(value)) {
            updatedQuery[key] = value.length === 1 ? value[0] : value
          } else {
            updatedQuery[key] = value.toString()
          }
        }
      })

      if (this.isQueryEqual(this.$route.query, updatedQuery)) {
        this.isInternalUpdate = false

        return
      }

      this.$router.replace({ query: updatedQuery })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error('Navigation error:', err)
          }
        })
        .finally(() => {
          // Keep internal update flag for a bit longer to prevent route watcher from triggering
          setTimeout(() => {
            this.isInternalUpdate = false
          }, 50)
        })
    },
    
    async loadStateFromQueryString() {
      const params = this.$route.query
      let gotQueryData = false

      // Handle search type FIRST and AWAIT it
      if (params.search_type) {
        await this.$store.dispatch('search/setSearchType', params.search_type)
        gotQueryData = true
      }

      // Handle page number
      const pageNum = parseInt(params.page, 10)
      
      this.currentPage = (!isNaN(pageNum) && pageNum > 0) ? pageNum : 1

      // Handle search query
      if (params.q) {
        this.query = params.q
        this.$refs.searchbar?.setQuery(params.q)
        gotQueryData = true
      }

      // Handle language
      if (params.language) {
        this.$refs.searchbar?.setInitialLanguage(params.language)
        gotQueryData = true
      }

      // Handle search type for search bar
      if (params.type) {
        this.$refs.searchbar?.setSearchType(params.type)
        gotQueryData = true
      }

      // Handle filters
      const qsFilters = []

      Object.entries(params).forEach(([key, value]) => {
        if (!['q', 'page', 'type', 'search_type'].includes(key)) {
          if (Array.isArray(value)) {
            value.forEach((v) => qsFilters.push([key, v]))
          } else if (value) {
            qsFilters.push([key, value])
          }
        }
      })

      if (qsFilters.length > 0) {
        this.facets = qsFilters
        gotQueryData = true
      } else {
        this.facets = []
      }

      return gotQueryData
    },

    handleRouteChange(route) {
      if (this.isInternalUpdate || this.searching) return
      
      const gotData = this.loadStateFromQueryString()

      if (gotData) {
        this.doSearch()
      }
    },
    getSearchUrl(item) {

      switch(this.currentSearchType) {
        case 'persons':
          return `/app/persons/${item.person.personid}`
        case 'definitions':
          return `/app/definitions/${item.definition.definitionid}`
        default:
          if (item.documentitemid?.breadcrumb) {
            const breadcrumb = JSON.parse(item.documentitemid.breadcrumb)
            const leafNode = breadcrumb.find(bi => bi.isleafnode)
            const lastItem = breadcrumb.slice(-1).pop()

            return `/app/document/${item.documentitemid.documentid?.documentid}/documentitem/${leafNode?.id}?documentitem_focus=${lastItem?.id}`
          }
          return null
      }
    },
    isQueryEqual(query1, query2) {
      if (!query1 || !query2) return false
      const keys1 = Object.keys(query1)
      const keys2 = Object.keys(query2)
      
      if (keys1.length !== keys2.length) return false

      return keys1.every((key) => query1[key] === query2[key])
    },

    onSearchBarSearch({ query, type, language }) {
      
      if (this.searching) {
        return
      }
      
      this.query = query
      this.currentPage = 1
      
      // Update URL with parameters
      const urlParams = {
        ...(query && { q: query }),
        ...(type !== 'default' && { type }),
        ...(language && { language }),
        page: '1',
        search_type: this.$store.state.search.currentSearchType
      }
      
      // Add current filters to URL params
      this.facets.forEach(([key, value]) => {
        urlParams[key] = value
      })
      
      // Set searching flag before updating URL
      this.searching = true
      
      this.updateUrl(urlParams)
      
      // Only search if we have a query or filters
      if (query || this.facets.length > 0) {
        this.doSearch()
      } else {
        this.searching = false
      }
    },

    async onSearchTypeChange(type) {      
      this.isLoading = true
      
      try {
        // First update the store - this will clear results
        await this.$store.dispatch('search/setSearchType', type)
        
        // Then update URL
        const currentQuery = { ...this.$route.query }

        delete currentQuery.page // Reset page
        
        this.updateUrl({ 
          ...currentQuery,
          search_type: type
        })

        // Reset page locally
        this.currentPage = 1

        // Only search if there's a query or filters
        if (this.query || this.facets.length > 0) {
          await this.doSearch()
        }
      } finally {
        this.isLoading = false
      }
    },

    async doSearch(data = null) {      
      if ((!this.query && this.facets.length === 0) || this.searching) {
        return
      }

      this.searching = true
      this.isLoading = true

      try {
        if (!data) {
          data = {
            query: this.query,
            page: this.currentPage,
            pageSize: this.pageSize,
            filters: this.facets,
            type: this.$route.query.type || 'default',
            language: this.$route.query.language
          }
        }

        await this.$store.dispatch('search/search', data)
      } catch (error) {
        console.error('Search error:', error)
      } finally {
        this.searching = false
        this.isLoading = false
      }
    },

    onPageChange(page) {
      // Prevent redundant updates and check for valid page
      if (this.page === page || this.searching || !page) return
            
      // Update local page state
      this.page = page
      
      // Update URL with new page number
      const currentQuery = { ...this.$route.query }
      const newQuery = {
        ...currentQuery,
        page: page.toString()
      }

      // Update URL first
      this.updateUrl(newQuery)

      // Then trigger search
      this.doSearch()
    },

    onFilterChange(payload) {
      if (this.searching) return

      // Create a new array to avoid reactive issues
      this.facets = [...payload]
      this.currentPage = 1

      // Group filters by key
      const filterParams = {}

      payload.forEach(([key, value]) => {
        filterParams[key] = filterParams[key] || []
        filterParams[key].push(value)
      })

      // Convert to URL format and update
      const urlParams = {
        ...this.$route.query, // Keep existing query params
        ...Object.fromEntries(
          Object.entries(filterParams).map(([key, values]) => [
            key,
            values.length === 1 ? values[0] : values
          ])
        ),
        page: '1'
      }

      // Remove any undefined/null values
      Object.keys(urlParams).forEach((key) => 
        urlParams[key] === undefined && delete urlParams[key]
      )

      // Update URL first
      this.updateUrl(urlParams);

      // Then search
      this.doSearch();
    },

    debouncedUpdateAndSearch: debounce(function(filterParams) {
      // Update URL first
      this.updateUrl({ 
        ...filterParams,
        page: '1'
      })

      // Then search
      this.doSearch()
    }, 300)

  }
}
</script>

<style scoped>
.active-filters-section {
  margin-top: -16px; /* To remove gap with search section */
}

.active-filters-label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.search-page {
  min-height: 100vh;
  background: #f5f5f7;
}

.search-section {
  padding: 12px 0;  /* Reduced from 16px */
  margin-bottom: 16px;  /* Reduced from 24px */
}

.search-container {
  max-width: 1280px !important;  /* Reduced from 1440px */
  padding: 0 16px;  /* Reduced from 0 24px */
}

.results-container {
  margin-bottom: 40px;
}

.results-header {
  margin-bottom: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.query-text {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.response-time {
  color: rgba(0, 0, 0, 0.4);
}

.results-card {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.98) !important;
  overflow: hidden;
}

.result-item {
  padding: 16px !important;
  transition: background-color 0.2s ease;
}

.result-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.result-title {
  display: flex !important;
  align-items: center !important;
  gap: 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
}

.score-chip {
  font-size: 11px !important;
  height: 20px !important;
}

.result-highlights {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.highlight-text {
  margin-bottom: 4px;
}

.result-metadata {
  margin-top: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.metadata-chip {
  font-size: 11px !important;
  height: 20px !important;
}

.document-title {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.result-divider {
  margin: 0 16px !important;
  opacity: 0.08;
}

.pagination-container {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.search-pagination :deep(.v-pagination__item) {
  box-shadow: none !important;
}

mark {
  background-color: rgba(255, 213, 79, 0.4);
  padding: 2px;
  border-radius: 2px;
}

@media (max-width: 960px) {
  .search-container {
    padding: 0 16px;
  }
}
</style>