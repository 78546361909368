<template>
  <v-app>
    <router-view :key="$route.fullPath"/>
    <Snackbar />
    <Confirm />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from '@/components/common/Snackbar'
import Confirm from '@/components/common/Confirm'

export default {
  components: {
    Snackbar,
    Confirm
  },
  computed: {
    ...mapGetters('account', ['loggedIn'])
  },
  mounted() {
    if (this.loggedIn()) {
      this.$store.dispatch('languages/getLanguages')
      this.$store.dispatch('app/initialiseApplicationSettings')
      this.$store.dispatch('account/getUser')
    }
  }
}
</script>
