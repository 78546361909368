<!-- components/SharingManager.vue -->
<template>
  <v-card class="sharing-manager" flat>
    <v-card-title class="text-h6" v-if="showHeader">
      Share '{{ documentName }}'
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-0 pb-0">
      <!-- Rest of your existing permission management UI -->
      <v-row no-gutters align="center" class="mb-4">
        <v-col>
      <v-autocomplete
        v-model="selectedEntity"
        :items="searchResults"
        :loading="searching"
        :search-input.sync="searchQuery"
        item-text="name"
        item-value="id"
        placeholder="Add people or groups"
        return-object
        hide-selected
        clearable
      >
        <template v-slot:item="{ item }">
          <div class="d-flex align-center" style="pointer-events: none">
            <v-icon class="mr-3">{{ item.type === 'group' ? 'mdi-account-group' : 'mdi-account' }}</v-icon>
            <div>
              <div>{{ item.name }}</div>
              <div class="caption text--secondary" v-if="item.email">{{ item.email }}</div>
            </div>
          </div>
        </template>
      </v-autocomplete>
    </v-col>
        <v-col cols="auto" class="ml-2">
          <v-btn 
            color="primary" 
            text
            @click="showGroupManager = true"
          >
            <v-icon left>mdi-account-group-outline</v-icon>
            Manage Groups
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Add GroupManager Dialog -->
    <v-dialog
      v-model="showGroupManager"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showGroupManager = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Manage Groups</v-toolbar-title>
        </v-toolbar>
        
        <v-card-text>
          <group-manager @group-updated="handleGroupUpdate" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Current Permissions -->
    <v-subheader>People and groups with access</v-subheader>
    <div class="permissions-list">
      <div 
        v-for="permission in pendingPermissions" 
        :key="`${permission.entity_type}-${permission.entity_id}`"
        class="permission-row d-flex align-center justify-space-between py-1 px-2"
      >
        <div class="d-flex align-center text-subtitle-2">
          <v-icon class="mr-3">
            {{ permission.entity_type === 'group' ? 'mdi-account-group' : 'mdi-account' }}
          </v-icon>
          <div>{{ permission.entity_name }}</div>
        </div>
        <div class="d-flex align-center">
          <v-select
            :value="permission.permission_level"
            :items="permissionLevels"
            item-text="name"
            item-value="name"
            dense
            hide-details
            class="permission-select mr-2 text-subtitle-2"
            @input="value => updatePermissionLevel(permission, value)"
          ></v-select>
          <v-btn icon small @click="removePermission(permission)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <!--<v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')">Cancel</v-btn>
      <v-btn color="primary" @click="savePermissions">Save</v-btn>
    </v-card-actions> -->

  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import DocAdmin from '@/store/data'
import { mapGetters, mapState, mapMutations } from 'vuex'
import GroupManager from '@/components/sharing/GroupManager'

export default {
  name: 'SharingManager',
  components: {
    GroupManager
  },
  props: {
    resourceId: {
      type: Number,
      required: true
    },
    documentName: {
      type: String,
      required: true
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    searching: false,
    searchQuery: '',
    selectedEntity: null,
    permissionLevels: [],
    currentPermissions: [],
    searchResults: [],
    visibility: {
      is_public: false,
      documentvisibilityid: null
    },
    showError: false,
    errorMessage: '',
    pendingPermissions: [], // Track changes before saving
    debouncedSearch: null, // Initialize the debounced function reference
    showGroupManager: false
  }),

  computed: {
    ...mapState('account', ['user'])
  },
  
  watch: {
    searchQuery(val) {
      if (this.debouncedSearch) {
        this.debouncedSearch(val)
      }
    },
    selectedEntity(val) {
      if (val) {
        this.addPermission(val)
        this.selectedEntity = null
        this.searchQuery = ''
      }
    },
    pendingPermissions: {
      deep: true,
      handler(newPermissions) {
        this.emitPermissionChanges()
      }
    }
  },
  created() {
    // Create the debounced function with proper binding
    this.debouncedSearch = debounce(this.performSearch, 300)
  },

  beforeDestroy() {
    // Cancel any pending debounced calls
    if (this.debouncedSearch) {
      this.debouncedSearch.cancel()
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {

    async initialize() {
      try {
        // Load permission levels
        const response = await DocAdmin.sharing.permissionLevels.list()

        this.permissionLevels = response.data.results
        // Load current permissions
        await this.loadCurrentPermissions()
      } catch (error) {
        this.showErrorMessage('Error initializing sharing settings')
      }
    },

    async loadCurrentPermissions() {
      try {        
        const response = await DocAdmin.sharing.documentPermissions.list({ params: { document_id: this.resourceId } })
        
        // Transform the API response to match our component's data structure
        const permissions = response.data.map((p) => ({
          entity_type: p.type,
          entity_id: p.id || `group-${p.name}`, // Ensure we have a unique ID for groups
          entity_name: p.name,
          permission_level: p.permission,
          permissionlevelid: this.permissionLevels.find(
            (level) => level.name === p.permission
          )?.permissionlevelid
        }))
        
        this.currentPermissions = permissions
        this.pendingPermissions = [...permissions]
      } catch (error) {
        this.showErrorMessage('Error loading permissions')
      }
    },

    async handleGroupUpdate() {
      // Refresh the search results and current permissions
      // in case group memberships have changed
      await this.loadCurrentPermissions()
      if (this.searchQuery) {
        await this.performSearch(this.searchQuery)
      }
      this.showGroupManager = false
    },
    
    // Modify performSearch to include the updated groups
    async performSearch(query) {
      if (!query || query.length < 2) {
        this.searchResults = []

        return
      }

      this.searching = true
      try {
        const [usersResponse, groupsResponse] = await Promise.all([
          DocAdmin.sharing.firebaseusers.list({
            params: { search: query }
          }),
          DocAdmin.sharing.groups.list({
            params: { search: query }
          })
        ])

        this.searchResults = [
          ...usersResponse.data.results.map((u) => ({
            id: u.uid,
            type: 'user',
            name: u.displayname || u.email,
            email: u.email,
            permissionLevelId: this.permissionLevels[0]?.permissionlevelid
          })),
          ...groupsResponse.data.results.map((g) => ({
            id: g.groupid,
            type: 'group',
            name: g.name,
            permissionLevelId: this.permissionLevels[0]?.permissionlevelid
          }))
        ]
      } catch (error) {
        this.showErrorMessage('Error searching for users and groups')
      }
      this.searching = false
    },

    addPermission(entity) {
      // Check if permission already exists
      const exists = this.pendingPermissions.some((p) => 
        p.entity_id === (entity.id || `group-${entity.name}`) && 
        p.entity_type === entity.type
      )
      
      if (!exists) {
        const defaultLevel = this.permissionLevels[0]
        const newPermission = {
          entity_type: entity.type,
          entity_id: entity.id || `group-${entity.name}`, // Ensure unique ID for groups
          entity_name: entity.name,
          permission_level: defaultLevel.name,
          permissionlevelid: defaultLevel.permissionlevelid
        }

        this.pendingPermissions.push(newPermission)
      }
    },

    updatePermissionLevel(permission, newLevel) {
      const index = this.pendingPermissions.findIndex((p) => 
        p.entity_id === permission.entity_id && 
        p.entity_type === permission.entity_type
      )

      if (index !== -1) {
        const permissionLevel = this.permissionLevels.find((level) => level.name === newLevel)
        
        this.pendingPermissions[index] = {
          ...permission,
          permission_level: newLevel,
          permissionlevelid: permissionLevel.permissionlevelid
        }
      }
    },

    removePermission(permission) {
      this.pendingPermissions = this.pendingPermissions.filter((p) => 
        !(p.entity_id === permission.entity_id && p.entity_type === permission.entity_type)
      )
    },

    emitPermissionChanges() {
      this.$emit('permissions-changed', this.pendingPermissions)
    },

    showErrorMessage(message) {
      this.$store.dispatch('app/setSnackBar', {
        message: message,
        color: 'error'
      })
    }
  }
}
</script>

<style scoped>
.permissions-list {
  border: thin solid rgba(0,0,0,0.12);
  border-radius: 0px;
  margin-bottom: 8px;
}
.permission-row {
  border-bottom: thin solid rgba(0,0,0,0.12)
}
.permission-row:last-child {
  border-bottom: none
}
.permission-select {
  max-width: 150px
}
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .3s ease-in-out;
}
</style>