<template>
  <v-container class="pt-3">
    <v-main>
      <router-view />
    </v-main>
  </v-container>
</template>

<script>
import config from '@/configs'

export default {
  data() {
    return {
      config
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>