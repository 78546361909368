<!-- components/GroupManager.vue -->
<template>
  <v-card class="group-manager" flat>
    <v-card-title class="d-flex align-center">
      <span class="text-h6">Groups</span>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="showCreateDialog = true">
        <v-icon left>mdi-plus</v-icon>
        New Group
      </v-btn>
    </v-card-title>

    <v-card-text>
      <!-- Groups List -->
      <v-data-table :headers="headers" :items="groups" :loading="loading" :search="search" class="elevation-1">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search
            Groups"
            prepend-inner-icon="mdi-magnify"
            clearable
            single-line
            hide-details
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.memberCount="{ item }">
          {{ item.members.length }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon small @click="editGroup(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon small @click="confirmDelete(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Create/Edit Group Dialog -->
    <v-dialog v-model="showCreateDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ editingGroup ? 'Edit Group' : 'Create New Group' }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="groupForm.name"
              label="Group
              Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>

            <v-textarea v-model="groupForm.description" label="Description" rows="3"></v-textarea>

            <!-- Member Management -->
            <v-subheader>Members</v-subheader>
            <v-autocomplete
              v-model="selectedUser"
              :items="userSearchResults"
              :loading="searchingUsers"
              :search-input.sync="userSearchQuery"
              item-text="displayname"
              item-value="uid"
              label="Add Members"
              placeholder="Search users"
              return-object
              hide-selected
              clearable
              @input="handleUserSelection"
            >
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.displayname }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>

            <!-- Current Members List -->
            <v-list dense class="member-list">
              <v-list-item v-for="member in groupForm.members" :key="member.uid">
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ member.displayname }}</v-list-item-title>
                  <v-list-item-subtitle>{{ member.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon small @click="removeMember(member)">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="saveGroup">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="showDeleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h6">Delete Group</v-card-title>
        <v-card-text>
          Are you sure you want to delete the group "{{ deleteGroup?.name }}"? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteGroupConfirmed">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import DocAdmin from '@/store/data'

export default {
  name: 'GroupManager',

  data: () => ({
    loading: false,
    search: '',
    groups: [],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Members', value: 'memberCount' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    showCreateDialog: false,
    showDeleteDialog: false,
    valid: false,
    groupForm: {
      name: '',
      description: '',
      members: []
    },
    editingGroup: null,
    deleteGroup: null,
    searchingUsers: false,
    userSearchQuery: '',
    userSearchResults: [],
    selectedUser: null,
    showError: false,
    errorMessage: ''
  }),

  watch: {
    userSearchQuery(newQuery) {
      if (this.debouncedSearchUsers) {
        this.debouncedSearchUsers(newQuery)
      }
    }
  },

  created() {
    // Bind the context properly when creating the debounced function
    this.debouncedSearchUsers = debounce((query) => {
      this.performUserSearch(query)
    }, 300)
    this.loadGroups()
  },

  methods: {
    async loadGroups() {
      this.loading = true
      try {
        const response = await DocAdmin.sharing.groups.list()

        this.groups = await Promise.all(response.data.results.map(async (group) => {
          const membersResponse = await DocAdmin.sharing.groups.members(group.groupid)

          return {
            ...group,
            members: membersResponse.data
          }
        }))
      } catch (error) {
        this.showErrorMessage('Error loading groups')
      }
      this.loading = false
    },

    async performUserSearch(query) {
      if (!query || query.length < 2) {
        this.userSearchResults = []

        return
      }

      this.searchingUsers = true
      try {
        const response = await DocAdmin.sharing.firebaseusers.list({
          params: { search: query }
        })

        this.userSearchResults = response.data.results.map((user) => ({
          uid: user.uid,
          displayname: user.displayname || user.email,
          email: user.email
        }))
      } catch (error) {
        this.showErrorMessage('Error searching users')
      }
      this.searchingUsers = false
    },

    handleUserSelection(user) {
      if (user) {
        this.addMember(user)
        this.selectedUser = null
        this.userSearchQuery = ''
      }
    },

    addMember(user) {
      if (!this.groupForm.members.some((m) => m.uid === user.uid)) {
        this.groupForm.members.push(user)
      }
    },

    removeMember(member) {
      this.groupForm.members = this.groupForm.members.filter((m) => m.uid !== member.uid)
    },

    editGroup(group) {
      this.editingGroup = group
      this.groupForm = {
        name: group.name,
        description: group.description,
        members: [...group.members]
      }
      this.showCreateDialog = true
    },

    async saveGroup() {
      if (!this.$refs.form.validate()) return

      try {
        if (this.editingGroup) {
          // Update existing group
          await DocAdmin.sharing.groups.update(this.editingGroup.groupid, {
            name: this.groupForm.name,
            description: this.groupForm.description
          })
          // Update members
          const currentMembers = new Set(this.editingGroup.members.map((m) => m.uid))
          const newMembers = new Set(this.groupForm.members.map((m) => m.uid))

          // Remove members who are no longer in the group
          const membersToRemove = [...currentMembers].filter((uid) => !newMembers.has(uid))

          await Promise.all(membersToRemove.map((uid) =>
            DocAdmin.sharing.groupMembers.delete_by_userid(this.editingGroup.groupid, uid)
          ))

          // Add new members
          const membersToAdd = [...newMembers].filter((uid) => !currentMembers.has(uid))

          await Promise.all(membersToAdd.map((uid) =>
            DocAdmin.sharing.groupMembers.create({
              groupid: this.editingGroup.groupid,
              firebaseuserid: uid
            })
          ))
        } else {
          // Create new group
          const groupResponse = await DocAdmin.sharing.groups.create({
            name: this.groupForm.name,
            description: this.groupForm.description
          })

          // Add members
          await Promise.all(this.groupForm.members.map((member) =>
            DocAdmin.sharing.groupMembers.create({
              groupid: groupResponse.data.groupid,
              firebaseuserid: member.uid
            })
          ))
        }

        await this.loadGroups()
        this.closeDialog()
        this.$emit('group-updated')
      } catch (error) {
        this.showErrorMessage('Error saving group')
      }
    },

    confirmDelete(group) {
      this.deleteGroup = group
      this.showDeleteDialog = true
    },

    async deleteGroupConfirmed() {
      try {
        await DocAdmin.sharing.groups.remove(this.deleteGroup.groupid)
        await this.loadGroups()
        this.showDeleteDialog = false
        this.$emit('group-updated')
      } catch (error) {
        this.showErrorMessage('Error deleting group')
      }
    },

    closeDialog() {
      this.showCreateDialog = false
      this.editingGroup = null
      this.groupForm = {
        name: '',
        description: '',
        members: []
      }
      this.$refs.form.reset()
    },

    showErrorMessage(message) {
      this.$store.dispatch('app/setSnackBar', {
        message: message,
        color: 'error'
      })
    }
  }
}
</script>

<style scoped>
.group-manager {
  max-width: 1200px;
  margin: 0 auto;
}

.member-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-top: 8px;
}
</style>