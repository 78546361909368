// components/search/results/PersonResult.vue
<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="result-title d-flex align-center">
        <router-link :to="getUrl(item)" target="_blank" class="text-decoration-none">
          <!-- Show highlighted name if available, otherwise fallback to regular name -->
          <span v-if="item.highlights?.name" v-html="item.highlights.name"></span>
          <span v-else>{{ item.name || item.person.englishname }}</span>
        </router-link>
        <v-chip
          x-small
          outlined
          class="score-chip ml-2"
          color="primary"
          title="Relevance Score"
        >
          {{ (item.score || 0).toFixed(2) }}
        </v-chip>
      </v-list-item-title>
      <!-- Highlights Section -->
      <v-list-item-subtitle v-if="hasHighlights" class="result-highlights">
        <!-- Body highlight -->
        <div v-if="item.highlights?.body" class="highlight-text mb-1">
          <v-icon x-small class="mr-1">mdi-text</v-icon>
          <span v-html="item.highlights.body"></span>
        </div>
        
        <!-- Namelipi highlight -->
        <div v-if="item.highlights?.namelipi" class="highlight-text mb-1">
          <v-icon x-small class="mr-1">mdi-translate</v-icon>
          <span v-html="item.highlights.namelipi"></span>
        </div>
        
        <!-- Bodylipi highlight -->
        <div v-if="item.highlights?.bodylipi" class="highlight-text">
          <v-icon x-small class="mr-1">mdi-text-box</v-icon>
          <span v-html="item.highlights.bodylipi"></span>
        </div>
      </v-list-item-subtitle>

      <!-- Metadata Section -->
      <template v-if="item.namelipi && !item.highlights?.namelipi">
        <div class="result-metadata mt-2 d-flex align-center"> 
          <v-divider vertical class="mx-2"></v-divider>
          <span class="lipi-text">{{ item.namelipi }}</span>
        </div>
      </template>
      
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'PersonResult',
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    hasHighlights() {
      return this.item.highlights && Object.keys(this.item.highlights).length > 0
    }
  },
  methods: {
    getUrl(item) {
      if (item.person?.personid) {
        return `/app/person/${item.person.personid}`
      }
    }
  }
}
</script>

<style scoped>
.result-title {
  line-height: 1.4;
}

.score-chip {
  font-size: 0.75rem;
}

.highlight-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.4;
}

.highlight-text :deep(mark) {
  background-color: rgba(255, 213, 79, 0.4);
  padding: 2px 0;
}

.metadata-chip {
  font-size: 0.75rem;
}

.lipi-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
}
</style>