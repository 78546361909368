<template>
  <div v-if="supportedLanguages && supportedLanguages.length >= 1">
    <v-btn-toggle
      v-model="toggleSelectedLanguages"
      multiple
      dense
      mandatory
    >
      <v-btn 
        v-for="languageId in supportedLanguages" 
        :key="languageId"
        :value="String(languageId)"
        text 
        small
        color="blue"
        class="text-uppercase"
      >
        {{ languageShortCodeById(languageId) }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'

export default {
  name: 'Toolbar',
  components: {
  },
  props: {},
  data: () => ({
    connectionType: configs.connectionProperties.types
  }),
  computed: {
    ...mapState('documents', ['supportedLanguages', 'selectedLanguages']),
    ...mapGetters('languages', ['languageShortCodeById']),
    ...mapGetters('documents', ['selectedLanguagesByDocument']),
    toggleSelectedLanguages: {
      get() {
        return this.selectedLanguagesByDocument()
      },
      set(v) {
        const documentId = this.$route.params.documentid
        const languageIds = v

        this.$store.commit('documents/SET_READING_LANGUAGE', { 
          documentId, 
          languageIds
        })
      }
    }
  }
}
</script>
