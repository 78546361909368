import DocAdmin from '@/store/data'
import router from '@/router'

const userAccessToken = localStorage.getItem('access_token')

const state = {
  accessToken: localStorage.getItem('access_token') || null, // makes sure the user is logged in even after
  refreshToken: localStorage.getItem('refresh_token') || null, // refreshing the page
  status: {
    loggedIn: userAccessToken ? true : false
  },
  user: undefined,
  returnUrl: undefined,
  isSuperUser: null,
  groups: []
}

const getters = {
  loggedIn: (state) => () => state.accessToken !== null,
  token: (state) => () => state.accessToken,
  allowRead: (state) => (documentTypeName) => {
    if (state.isSuperUser) { return true }

    return state.groups.some((g) => g.match(documentTypeName.toLowerCase()))
  },
  allowEdit: (state) => (documentTypeName) => {
    if (state.isSuperUser) { return true }

    return state.groups.some((g) => g.match(documentTypeName.toLowerCase() + '_(editor|manager)'))
  },
  isManager: (state) => (documentTypeName) => {
    if (state.isSuperUser) { return true }

    return state.groups.some((g) => g.match(documentTypeName.toLowerCase() + '_manager'))
  }
}

const mutations = {
  RETURN_URL(state, url) {
    state.returnUrl = url
  },
  LOGGED_IN(state, status) {
    state.status.loggedIn = status
  },
  ACCOUNT_LOGOUT(state) { //destroyToken
    state.status = {}
    state.user = null
    state.accessToken = null
    state.refreshToken = null
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  },
  UPDATE_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
    localStorage.setItem('access_token', accessToken)
  },
  ACCOUNT_LOGIN(state, token) {
    state.status = { loggingIn: true }
    localStorage.setItem('access_token', token.access)
    localStorage.setItem('refresh_token', token.refresh)
    state.accessToken = token.access
    state.refreshToken = token.refresh
    router.push(state.returnUrl || '/')
  },
  ACCOUNT_LOGIN_FAILURE(state) {
    state.status = {}
    state.user = null
  },
  ACCOUNT_USER(state, userObject) {
    state.user = userObject
    state.isSuperUser = userObject.is_superuser
    state.groups = userObject.groups.map(({ name }) => name)
  }
}

const actions = {
  logout ({ commit, getters, state }) {
    if (getters.loggedIn) {
      return DocAdmin.account.logout.create({ refresh: state.refreshToken })
        .catch((err) => console.error(`logout: error logging off: ${err}`))
        .finally(() => {
          console.log('logout: clearing tokens')
          commit('ACCOUNT_LOGOUT')
        })
    }

    return null
  },
  // run the below action to get a new access token on expiration
  refreshToken ({ commit, state }) {
    return DocAdmin.account.tokenRefresh.create({ refresh: state.refreshToken })
      .then((response) => {
        console.log('refreshToken: token refreshed')
        commit('UPDATE_ACCESS_TOKEN', response.data.access)
      })
      .catch((err) => { console.error(`refreshToken: error refreshing token: ${err}`) })
  },

  /**
   * Register User
   * 
   * @param {commit} context 
   * @param {Object} data = {name:<String>, email:<String>, username:<String>, password:<String>, confirm:<String>}
   * @returns {Promise}
   */
  register ({ commit }, data) {
    return DocAdmin.account.register.create(data)
      .then((response) => {
        console.log('register: account registered')
        commit('UPDATE_ACCESS_TOKEN', response.data.access)
      })
      .catch((err) => { console.log(`error refreshing token: ${err}`) })
  },

  /**
   * Login
   * @param {commit}
   * @param {dispatch}
   * 
   * @returns {Boolean} indicated a successful login
   */
  login ({ commit, dispatch }, credentials) {
    
    return DocAdmin.account.token.create({
      username: credentials.username,
      password: credentials.password
    })
      .then((response) => {
        commit('ACCOUNT_LOGIN', response.data)
        dispatch('getUser')

        return Promise.resolve(response)
      })
      .catch((err) => { 
        console.error(`login: error logining token=${err}`) 
        commit('ACCOUNT_LOGIN_FAILURE')

        return Promise.reject(err)
      })
  },

  checkToken({ commit }) {
    const body = {
      token: localStorage.getItem('refresh_token')
    }

    if (!body.token) {
      commit('LOGGED_IN', false)

      return false
    }

    return true
  },

  getUser ({ commit }) {
    return DocAdmin.account.profile.list()
      .then((response) => {
        commit('ACCOUNT_USER', response.data)
      })
      .catch((err) => { 
        console.error(`getUser: error getting user data=${err}`) 
      })
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
