// SearchTypeSelector.vue
<template>
  <div class="search-type-selector d-flex justify-center">
    <v-tabs
      v-model="activeTab"
      background-color="transparent"
      center-active
      hide-slider
      color="primary"
      class="flex-grow-0"
    >
      <v-tab
        v-for="(type, index) in searchTypes"
        :key="type.value"
        :value="index"
        class="text-none"
      >
        <v-icon small class="mr-2">{{ type.icon }}</v-icon>
        {{ type.label }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SearchTypeSelector',
  data: () => ({
    activeTab: 0,
    searchTypes: [
      {
        value: 'documents',
        label: 'Documents',
        icon: 'mdi-file-document-outline'
      },
      {
        value: 'persons',
        label: 'Persons',
        icon: 'mdi-account-outline'
      },
      {
        value: 'definitions',
        label: 'Definitions',
        icon: 'mdi-book-open-variant'
      }
    ]
  }),
  computed: {
    ...mapState('search', ['currentSearchType'])
  },
  watch: {
    // Watch active tab changes to update store with correct type value
    activeTab(newIndex) {
      const newType = this.searchTypes[newIndex]?.value
      if (newType && newType !== this.currentSearchType) {
        this.setSearchType(newType)
        this.$emit('change', newType)
      }
    },
    // Watch store changes to update active tab
    currentSearchType: {
      immediate: true,
      handler(newType) {
        const index = this.searchTypes.findIndex(type => type.value === newType)
        if (index !== -1 && index !== this.activeTab) {
          this.activeTab = index
        }
      }
    }
  },
  methods: {
    ...mapActions('search', ['setSearchType'])
  },
  created() {
    // Set initial type from URL or default to 'documents'
    const urlParams = new URLSearchParams(window.location.search)
    const searchType = urlParams.get('search_type') || 'documents'
    
    // Find the index of the type
    const index = this.searchTypes.findIndex(type => type.value === searchType)
    
    // Set initial active tab and store state
    if (index !== -1) {
      this.activeTab = index
      if (searchType !== this.currentSearchType) {
        this.setSearchType(searchType)
      }
    }
  }
}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}
</style>