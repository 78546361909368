<template>
  <div class="search-container">
    <v-card flat class="search-card" :elevation="isFocused ? 3 : 1">
      <div class="d-flex align-center search-inner">
        <!-- Search Type Selector -->
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text x-small class="mode-btn" v-bind="attrs" v-on="on">
              <template v-if="searchType === 'default'">N</template>
              <template v-else-if="searchType === 'wildcard'">*</template>
              <template v-else>
                <v-icon x-small>mdi-regex</v-icon>
              </template>
            </v-btn>
          </template>
          <v-list class="search-type-menu" dense>
            <v-list-item
              v-for="type in searchTypes"
              :key="type.value"
              @click="setSearchType(type.value)"
              :class="{ 'selected-type': searchType === type.value }"
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <span class="search-type-icon mr-2">
                    <template v-if="type.value === 'default'">N</template>
                    <template v-else-if="type.value === 'wildcard'">*</template>
                    <template v-else>
                      <v-icon small>mdi-regex</v-icon>
                    </template>
                  </span>
                  {{ type.text }}
                </v-list-item-title>
                <v-list-item-subtitle class="search-type-subtitle">
                  {{ type.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="divider"></v-divider>

        <!-- Language Selector -->
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text x-small class="mode-btn" v-bind="attrs" v-on="on">
              {{ selectedLanguageShort }}
            </v-btn>
          </template>
          <v-list class="language-menu" dense>
            <v-list-item
              v-for="lang in languages"
              :key="lang.code"
              @click="setLanguage(lang.code)"
              :class="{ 'selected-language': selectedLanguage === lang.code }"
            >
              <v-list-item-content>
                <v-list-item-title class="language-title">
                  {{ lang.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="divider"></v-divider>

        <!-- Search Input -->
        <v-text-field
          v-model="searchQuery"
          :loading="loading"
          class="search-input"
          placeholder="Search"
          hide-details
          solo
          flat
          @focus="isFocused = true"
          @blur="isFocused = false"
          @keyup.enter="search"
        >
          <template v-slot:append>
            <v-icon
              v-if="searchQuery"
              small
              color="grey darken-1"
              @click="clearSearch"
              class="clear-icon"
            >
              mdi-close-circle
            </v-icon>
            <v-icon 
              small 
              color="grey darken-1" 
              class="search-icon"
              @click="search"
            >
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    searchQuery: '',
    searchType: 'default',
    selectedLanguage: 'en',
    isFocused: false,
    languages: [
      { code: 'en', name: 'English', short: 'En' },
      { code: 'gu', name: 'ગુજરાતી (Gujarati)', short: 'Gu' },
      { code: 'hi', name: 'हिंदी (Hindi)', short: 'Hi' },
      { code: 'sa', name: 'संस्कृत (Sanskrit)', short: 'Sa' }
    ],
    searchTypes: [
      { 
        value: 'default', 
        text: 'Normal Search',
        description: 'Standard text search with fuzzy matching'
      },
      { 
        value: 'wildcard', 
        text: 'Wildcard',
        description: 'Use * for partial matches'
      },
      { 
        value: 'regex', 
        text: 'Regular Expression',
        description: 'Use patterns for advanced matching'
      }
    ]
  }),
  
  computed: {
    selectedLanguageShort() {
      const lang = this.languages.find((l) => l.code === this.selectedLanguage)

      return lang ? lang.short : 'En'
    }
  },
  
  methods: {
    search() {
      const trimmedQuery = this.searchQuery.trim()
      
      this.$emit('search', {
        query: trimmedQuery,
        type: this.searchType || 'default',  // Ensure default if not set
        language: this.selectedLanguage !== 'en' ? this.selectedLanguage : null
      })
    },

    setLanguage(code) {
      this.selectedLanguage = code
      if (this.searchQuery.trim()) {
        this.search()
      }
    },

    setSearchType(type) {
      if (type === this.searchType) return
      
      this.searchType = type
      // Always emit search event with current state to update URL params
      this.$emit('search', {
        query: this.searchQuery.trim(),
        type: type,
        language: this.selectedLanguage !== 'en' ? this.selectedLanguage : null
      })
    },

    clearSearch() {
      this.searchQuery = ''
      this.$emit('search', {
        query: '',
        type: this.searchType,
        language: this.selectedLanguage !== 'en' ? this.selectedLanguage : null
      })
    },

    setQuery(query) {
      this.searchQuery = query
    },

    setInitialLanguage(language) {
      if (language && this.languages.some((l) => l.code === language)) {
        this.selectedLanguage = language
      }
    }
  },
  
  created() {
    const params = new URLSearchParams(window.location.search)
    
    // Set initial search query if present
    if (params.get('q')) {
      this.searchQuery = params.get('q')
    }
    
    // Set initial search type, default to 'default' if not specified
    this.searchType = params.get('type') || 'default'
    
    // Set initial language if specified
    if (params.get('language')) {
      this.setInitialLanguage(params.get('language'))
    }
  }
}
</script>

<style scoped>
.search-container {
  max-width: 720px;
  margin: 0 auto;
  padding: 0 16px;
}

.search-card {
  border-radius: 8px !important;
  background: transparent !important;
}

.search-inner {
  padding: 0 4px;
  background: white;
  border-radius: 8px;
}

.mode-btn {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  min-width: 24px !important;
  height: 24px !important;
  padding: 0 4px !important;
}

.mode-btn :deep(.v-icon) {
  font-size: 16px !important;
}

.divider {
  height: 16px !important;
  margin: 0 4px !important;
  opacity: 0.12;
}

.search-input {
  font-size: 14px !important;
  margin-top:8px;
}

.search-input :deep(.v-input__slot) {
  min-height: 36px !important;
  box-shadow: none !important;
  background: transparent !important;
}

.search-type-menu, .language-menu {
  border-radius: 4px !important;
  overflow: hidden;
  min-width: 200px;
}

.search-type-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.selected-type, .selected-language {
  background: rgba(var(--v-primary-base), 0.1) !important;
}

.clear-icon, .search-icon {
  opacity: 0.6;
  cursor: pointer;
}

.clear-icon:hover, .search-icon:hover {
  opacity: 1;
}
</style>