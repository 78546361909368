// store/modules/search/index.js
import DocAdmin from '@/store/data'

export default {
  namespaced: true,
  state: () => ({
    currentSearchType: 'documents',
    searchResults: null,
    searchQueryParams: undefined
  }),
  
  mutations: {
    SET_SEARCH_TYPE(state, type) {
      state.currentSearchType = type
    },
    SET_SEARCH_RESULTS(state, { results, qs }) {
      state.searchResults = results
      state.searchQueryParams = qs
    },
    CLEAR_SEARCH_RESULTS(state) {
      state.searchResults = null
    }
  },
  
  actions: {
    clearSearchResults({ commit }) {
      commit('CLEAR_SEARCH_RESULTS')
    },
    
    async setSearchType({ commit, dispatch }, type) {
      await dispatch('clearSearchResults')
      commit('SET_SEARCH_TYPE', type)
    },
  
    async search({ commit, state }, payload) {
      const filters = payload.filters || []
      
      // Build base params
      const params = [
        ...filters,
        ['page', payload.page || 1],
        ['page_size', payload.pageSize || 20]
      ]
  
      // Add search query params
      if (payload.query) {
        params.push(['q', payload.query])
        if (payload.type && payload.type !== 'default') {
          params.push(['type', payload.type])
        }
        if (payload.language) {
          params.push(['language', payload.language])
        }
      }
  
      // Clean params
      const cleanParams = new URLSearchParams(
        params.filter(([_, value]) => value != null)
      )
      const apiParams = Object.fromEntries(cleanParams.entries())
  
      try {
        let response
        
        // Use the correct API endpoint based on search type
        switch (state.currentSearchType) {
          case 'persons':
            response = await DocAdmin.search.persons.list({ params: apiParams })
            break
          case 'definitions':
            response = await DocAdmin.search.definitions.list({ params: apiParams })
            break
          default:
            response = await DocAdmin.search.documents.list({ params: apiParams })
        }
  
        const results = response.data
        commit('SET_SEARCH_RESULTS', { results, qs: cleanParams })
        return results
        
      } catch (error) {
        console.error('Search error:', error)
        throw error
      }
    }
  }
}