import Vue from 'vue'
import App from '@/App.vue'

import ConfirmPlugin from './plugins/confirm'

// VUEX - https://vuex.vuejs.org/
import store from '@/store'

// VUE-ROUTER - https://router.vuejs.org/
import router from '@/router'

// PLUGINS
import './plugins/vue-head'
import vuetify from './plugins/vuetify'
// import logger from './plugins/logger'
import _ from 'lodash'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'
import './assets/scss/custom.scss'

// enable lodash in all components
Object.defineProperty(Vue.prototype, '$_', { value: _ })

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

// Initialize plugins before creating Vue instance

// Filters
Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()

  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('stripHtml', (value) => {
  return value.replace(/<\/?[^>]+>/ig, ' ')
})

Vue.filter('uppercase', (value) => {
  return value.toUpperCase() || ''
})

Vue.filter('truncate', (text, stop, clamp) => {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('titlecase', (value) => {
  if (!value) { return value }

  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase())
})

const app = new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')

// Initialize plugins after creating Vue instance
Vue.use(ConfirmPlugin, { vuetify })

export default app