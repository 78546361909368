<template>
  <v-snackbar v-model="show" :color="$store.state.app.snackbar.color"
    :timeout="$store.state.app.snackbar.timeout || 3000">
    <div v-html=$store.state.app.snackbar.message></div>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    show: {
      get() {
        return this.$store.state.app.snackbar.show
      },
      set(value) {
        if (!value) {
          this.$store.commit('app/hideSnackBar')
        }
      }
    }
  }
}
</script>