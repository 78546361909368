<template>
  <v-card flat tile class="mb-2">
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>

    <v-card-text>
      <ValidationObserver ref="document_form" tag="form">

        <ValidationProvider v-slot="{ errors }" name="englishtitle" rules="required|max:255">
          <v-text-field
            v-model="documentLocal.englishtitle"
            label="English Title"
            data-vv-name="englishtitle"
            :error-messages="errors"
          />
        </ValidationProvider>

        <v-row justify="start" no-gutters>
          <v-col cols="6">
            <ValidationProvider v-slot="{ errors }" name="documenttypeid" rules="required|max:255">
              <DocumentTypeLookupField
                v-model="dti"
                :value="documentLocal.documenttypeid"
                :error-messages="errors"
                name="documenttypeid"
                @input="updateLocal('documenttypeid', $event)"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <DataStructureLookupField
              :value="documentLocal.datastructureid"
              name="datastructureid"
              @input="updateLocal('datastructureid', $event)"
            />
          </v-col>
        </v-row>
        <!-- Add visibility section -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-switch
              v-model="documentLocal.ispublic"
              :label="documentLocal.ispublic ? 'Public' : 'Private'"
              @change="handleVisibilityChange"
            ></v-switch>

            <!-- Show sharing manager when document is private -->
            <sharing-manager
              v-if="!documentLocal.ispublic && documentLocal.documentid"
              ref="sharingManager"
              :resource-id="documentLocal.documentid"
              :document-name="documentLocal.englishtitle"
              @permissions-changed="handlePermissionsChanged"
            />
          </v-col>
        </v-row>
        <v-btn @click="determineSave">{{ buttonText }}</v-btn>

      </ValidationObserver>
    </v-card-text>

    <debug v-if="debug">
      <template slot="sub-heading">DocumentEditForm</template>
      <template slot="info" class="text-xs">
        documentLocal={{ documentLocal }},<br/>
        document={{ document }}<br/>
        isNewRecord={{ isNewRecord }}
      </template>
    </debug>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import configs from '@/configs'
import Vue from 'vue'

import DocumentTypeLookupField from '@/components/shared/DocumentTypeLookupField'
import DataStructureLookupField from '@/components/shared/DataStructureLookupField'
import AuthorMultipleLookupField from '@/components/shared/AuthorMultipleLookupField'
import SharingManager from '@/components/sharing/SharingManager'
import DocAdmin from '@/store/data'

import Debug from '@/components/common/Debug'

import _ from 'lodash'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: 'Phone number must be valid'
})

export default {
  name: 'DocumentForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    DocumentTypeLookupField,
    DataStructureLookupField,
    AuthorMultipleLookupField,
    SharingManager,
    Debug
  },
  props: {
    document: {
      type: Object
    },
    save: {
      type: Function
    },
    buttonText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      dti: null,
      connectionType: configs.connectionProperties.types,
      error: {
        show: false,
        message: null
      },
      changed: false,
      valid: false,
      documentLocal: {},
      pendingPermissions: []
    }
  },
  computed: {
    ...mapState('app', ['debug']),
    isNewRecord () {
      return !this.documentLocal.documentid
    }
  },
  created() {
    if (this.document && Object.keys(this.document).length) {
      this.documentLocal = _.cloneDeep(this.document)
    }
  },
  methods: {
    ...mapActions('documents', ['createDocument']),
    handlePermissionsChanged(permissions) {
      this.pendingPermissions = permissions
    },
    async handleVisibilityChange(isPublic) {
      if (isPublic) {
        const confirmed = await this.$confirm(
          'Making this document public will remove all existing permissions. Do you want to proceed?',
          {
            title: 'Confirm Visibility Change',
            confirmText: 'Proceed',
            cancelText: 'Cancel',
            color: 'error'
          }
        )

        if (!confirmed) {
          this.updateLocal('ispublic', false)

          return
        }
      }
      
      this.updateLocal('ispublic', isPublic)
      // The actual saving will be handled by the main save button
    },
    updateLocal (field, value) {
      if (field === 'documenttypeid') {
        this.dti = value
      }
      Vue.set(this.documentLocal, field, value)
    },
    async saveDocument() {
      this.$refs.document_form.validate().then(async (isValid) => {
        if (!isValid) { 
          console.error('DocumentForm.saveDocument form is not valid')

          return
        }

        // Prepare document data
        if ('documenttypeid' in this.documentLocal) {
          this.documentLocal.documenttypeid_id = this.documentLocal.documenttypeid.documenttypeid
        }
        
        if ('datastructureid' in this.documentLocal) {
          this.documentLocal.datastructureid_id = this.documentLocal.datastructureid.datastructureid
        }

        try {
          // First save the document
          const documentId = await this.createDocument(this.documentLocal)
          
          // Then save permissions if the document is private
          if (!this.documentLocal.ispublic && this.pendingPermissions.length > 0) {
            await DocAdmin.sharing.documentPermissionsBatch.create({
              document_id: documentId.documentid,
              permissions: this.pendingPermissions.map((p) => ({
                type: p.entity_type,
                id: p.entity_id,
                permission: p.permission_level
              }))
            })
          }

          this.$store.dispatch('app/setSnackBar', 'New Document and permissions added.')
          this.$router.push({ 
            name: 'document_edit', 
            params: {
              documentid: documentId.documentid
            }
          })
        } catch (error) {
          console.error('Error saving document and permissions:', error)
          this.$store.dispatch('app/setSnackBar', {
            message: 'Error saving document and permissions',
            color: 'error'
          })
        }
      })
    },
    async saveProxy() {
      try {
        // First save the document
        await this.save(this.documentLocal)

        // Then save permissions if the document is private
        if (!this.documentLocal.ispublic && this.pendingPermissions.length > 0) {
          await DocAdmin.sharing.documentPermissionsBatch.create({
            document_id: this.documentLocal.documentid,
            permissions: this.pendingPermissions.map((p) => ({
              type: p.entity_type,
              id: p.entity_id,
              permission: p.permission_level
            }))
          })
        }
        
        // Log if visibility changed from private to public
        if (this.document.ispublic === false && this.documentLocal.ispublic === true) {
          console.log('Document visibility changed from private to public, removing permissions')
          await DocAdmin.sharing.documentPermissionsBatch.create({
            document_id: this.documentLocal.documentid,
            permissions: []
          })
        }
        
        this.$store.dispatch('app/setSnackBar', 'Document and permissions updated.')
      } catch (error) {
        console.error('Error saving document and permissions:', error)
        this.$store.dispatch('app/setSnackBar', {
          message: 'Error saving document and permissions',
          color: 'error'
        })
      }
    },
    determineSave() {
      if (this.isNewRecord) {
        this.saveDocument()

        return
      }
      this.saveProxy()
    }
  }
}
</script>