import { render, staticRenderFns } from "./DefinitionResult.vue?vue&type=template&id=3b3998ea&scoped=true"
import script from "./DefinitionResult.vue?vue&type=script&lang=js"
export * from "./DefinitionResult.vue?vue&type=script&lang=js"
import style0 from "./DefinitionResult.vue?vue&type=style&index=0&id=3b3998ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3998ea",
  null
  
)

export default component.exports