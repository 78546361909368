<template>
  <v-card tile flat>
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>

    <ImageManager
      v-if="isRenderComponent('enableItemImage')"
      :id="documentItem.documentitemid"
      :connection-type-id="connectionType.documentItems"
      :allow-upload="true"
    />

    <v-card-text>
      <ValidationObserver ref="document_item_form" tag="form">
        <v-row justify="start" no-gutters>
          <v-col
            v-if="renderField(documentItemTypeIdLocal, 'number')"
            cols="4"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            <ValidationProvider v-slot="{ errors }" name="number" :rules="rules('number', 'required')">
              <v-text-field 
                v-model="documentItemLocal.number" 
                type="number"
                label="Number"
                data-vv-name="number"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="renderField(documentItemTypeIdLocal, 'englishtitle')"
            cols="8"
            sm="8"
            md="4"
            lg="4"
            xl="4"
          >
            <ValidationProvider 
              v-slot="{ errors }" 
              name="englishtitle" 
              :rules="rules('englishtitle', 'required|max:255')"
            >
              <v-text-field 
                v-model="documentItemLocal.englishtitle"
                label="English Title"
                data-vv-name="englishtitle"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col 
            cols="12"
            sm="6"
            md="2"
            lg="2"
            xl="2"
          >
            <v-text-field
              v-model="documentItemLocal.parentdocumentitemid" 
              :readonly="isSuperUser ? false : true"
              type="number"
              label="Parent DocumentItem Id"
            />
          </v-col>
          <v-col 
            cols="12"
            sm="6"
            md="2"
            lg="2"
            xl="2"
          >
            <ValidationProvider v-slot="{ errors }" name="documentid" rules="required|max:10">
              <v-text-field
                v-model="documentItemLocal.documentid" 
                :readonly="isSuperUser ? false : true"
                type="number"
                label="Document Id"
                data-vv-name="documentid"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col 
            cols="12"
            sm="6"
            md="2"
            lg="2"
            xl="2"
          >
            <ValidationProvider v-slot="{ errors }" name="documentitemtypeid" rules="required">
              <DocumentItemTypeLookupField
                v-model="diti"
                :value="documentItemLocal.documentitemtypeid"
                :error-messages="errors"
                :allowed-items="allowedDocumentItems"
                name="documentitemtypeid"
                @input="updateLocal('documentitemtypeid', $event)"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <div>
        <v-switch
          v-model="documentItemLocal.ispublic"
          :label="documentItemLocal.ispublic ? 'Public' : 'Private'"
          @input="updateLocal('ispublic', $event)"
          ></v-switch>
        </div>
      </ValidationObserver>
    </v-card-text>
    
    <v-card-actions>
      <v-btn :disabled="!changed" @click="determineSave">{{ buttonText }}</v-btn>
      <ConfirmDialog
        v-if="dialogs.delete" 
        :message="`Are you sure you want to delete ${documentItemTypeName(documentItem.documentitemtypeid)} (${documentItem.documentitemid}) and all its translations?`"
        :title="`Delete ${documentItemTypeName(documentItem.documentitemtypeid)}`"
        bar-color="red"
        @close="dialogs.delete = false"
        @delete="remove(documentItem.documentitemid)"
      />
      <v-btn v-if="!isNewRecord" color="error" @click="dialogs.delete = true">Delete Document Item & Translations</v-btn>
    </v-card-actions>
    <debug v-if="debug">
      <template slot="sub-heading">DocumentItemForm</template>
      <template slot="info" class="text-xs">
        getDocumentItemDataStructureById= {{ getDocumentItemDataStructureById(documentItemTypeIdLocal) }},<br/><br/>
        parentDocumentItem={{  parentDocumentItem }}<br/>
        documentItem={{ documentItem }}<br/><br/>
        documentItemLocal={{ documentItemLocal }}<br/><br/>
        diti={{ diti }}<br/><br/>
        changed={{ changed }}
      </template>
    </debug>
  </v-card>
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import configs from '@/configs'
import Vue from 'vue'

import DocumentItemTypeLookupField from '@/components/shared/DocumentItemTypeLookupField'
import ConfirmDialog from '@/components/shared/ConfirmDialog'
import ImageManager from '@/components/ImageManager'

import bus from '@/eventbus'
import types from '@/helpers/types'
import Debug from '@/components/common/Debug'
import _ from 'lodash'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: 'Phone number must be valid'
})

export default {
  name: 'DocumentItemForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    DocumentItemTypeLookupField,
    ConfirmDialog,
    ImageManager,
    Debug
  },
  props: {
    documentItem: {
      type: Object
    },
    save: {
      type: Function
    },
    buttonText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      diti: null,
      connectionType: configs.connectionProperties.types,
      dialogs: {
        delete: false
      },
      error: {
        show: false,
        message: ''
      },
      changed: false,
      documentItemLocal: {}
    }
  },
  computed: {
    ...mapState('app', ['debug']),
    ...mapState('account', ['isSuperUser']),
    ...mapGetters('documents', [
      'renderComponent', 
      'renderField', 
      'itemFieldRules',
      'getDocumentDataStructure',
      'getAllowedChildrenById',
      'getDocumentItemDataStructureById'
    ]),
    ...mapState('documentItems', ['parentDocumentItem']),
    isNewRecord () {
      return !this.documentItemLocal.documentitemid
    },
    allowedDocumentItems() {
      if (this.isNewRecord && this.parentDocumentItem) {
        if (Object.keys(this.parentDocumentItem).length === 0) { 
          return this.getDocumentDataStructure().allowedDocumentItems 
        }
        console.log(`allowedDocumentItems.isNewRecord && parentDocumentItem = ${this.getAllowedChildrenById(this.parentDocumentItem.documentitemtypeid)}`)

        return this.getAllowedChildrenById(this.parentDocumentItem.documentitemtypeid)
      }

      if (this.getDocumentDataStructure()) {
        console.log(`allowedDocumentItems.getDocumentDataStructure = ${this.getDocumentDataStructure().allowedDocumentItems}`)

        return this.getDocumentDataStructure().allowedDocumentItems
      }

      return []
    },
    documentItemTypeIdLocal() {
      if (!'documentitemtypeid' in Object.keys(this.documentItemLocal)) {
        return null
      }

      let documentItemTypeId = Number(this.documentItemLocal.documentitemtypeid)

      if (this.documentItemLocal.documentitemtypeid instanceof Object) {
        documentItemTypeId = Number(this.documentItemLocal.documentitemtypeid.documentitemtypeid)
      }

      return documentItemTypeId
    }
  },
  watch: {
    documentItemLocal: {
      deep: true,
      handler(a) {
        if (JSON.stringify(this.documentItem) !== JSON.stringify(a)) {
          this.changes(true)

          return
        }
        this.changes(false)
      }
    }
  },
  created() {
    bus.$on('update_document_item', ({ field, value }) => {
      Vue.set(this.documentItemLocal, field, value)
    })

    if (!this.$route.params.documentitemid) { 
      this.loading = false
      this.initData()

      return 
    }
    this.loaddata()
  },
  beforeDestroy () {
    bus.$off('update_document_item')
  },
  methods: {
    rules(fieldName, rule) {
      const dit = this.documentItemTypeIdLocal

      if (!dit) { return '' }

      return this.itemFieldRules(dit, fieldName, rule)
    },
    async remove() {
      try {
        await this.$store.dispatch('documentItems/deleteDocumentItemsWithChildren', this.documentItemLocal.documentitemid)
        this.$store.dispatch('app/setSnackBar', 'Deleted Document Items with Children id=' + this.documentItemLocal.documentitemid)
        this.$router.push({
          name: 'document_home',
          params: {
            documentid: this.$route.params.documentid
          }
        })
      } catch (err) {
        this.error.show = true
        this.error.message = `There was a failure in deleting, error: ${JSON.stringify(err)}`
      }
    },
    async saveDocumentItem() {
      if ('documentitemtypeid' in this.documentItemLocal) {
        this.documentItemLocal.documentitemtypeid = Number(this.documentItemLocal.documentitemtypeid.documentitemtypeid)
      }

      // const payload = (({ documentitemtypeid, ...o }) => o)(this.documentItemLocal) // remove documentitemtypeid
      const documentItem = await this.$store.dispatch('documentItems/createDocumentItem', this.documentItemLocal)

      this.$store.dispatch('app/setSnackBar', 'New Document Item added.')
      this.$router.push({ 
        name: 'documentitem_edit', 
        params: {
          documentitemid: documentItem.documentitemid
        }
      })
    },
    saveProxy() {
      this.save(this.documentItemLocal)
    },
    determineSave() {
      this.$refs.document_item_form.validate().then(async (isValid) => {
        if (!isValid) { 
          this.error.show = true
          this.error.message = 'Please fill in required fields'
          console.error('DocumentItemForm.saveDocumentItem form is not valid')

          return
        }

        try {
          this.isNewRecord ? this.saveDocumentItem() : this.saveProxy()
          this.changes(false)
        } catch (err) {
          console.log(`determineSave error = ${err}`)
        }
        
      })
    },
    updateLocal (field, value) {
      if (field === 'documentitemtypeid') { this.diti = value }
      Vue.set(this.documentItemLocal, field, value)
      Vue.set(this, 'changed', true)
    },
    isRenderComponent (componentName) {
      if (!('documentitemtypeid' in Object.keys(this.documentItemLocal))) {
        return false
      }

      return this.renderComponent(this.documentItemLocal.documentitemtypeid, componentName)
    },
    changes (changes) {
      this.$emit('pendingChanges', changes)
      this.changed = changes
    },
    async loaddata() {
      if (!(this.documentItem && Object.keys(this.documentItem).length)) {
        await this.$store.dispatch('documentItems/getDocumentItem', this.$route.query.documentitemid)
      }

      this.documentItemLocal = _.cloneDeep(this.documentItem)
      if (!this.documentItemLocal) {
        this.initData()
      }
      this.diti = this.documentItemLocal.documentitemtypeid
    },
    initData() {
      this.documentItemLocal = {}
      this.documentItemLocal.documentid = this.$route.params.documentid ? this.$route.params.documentid : null
      this.documentItemLocal.parentdocumentitemid = this.$route.query.parentdocumentitemid

      if (this.allowedDocumentItems.length === 1) {
        const [allowedDocumentItemType] = this.allowedDocumentItems

        if (!(allowedDocumentItemType in types.documentItemTypes)) {
          console.error(`DocumentItemForm.initialiseEmpty could not find key=${allowedDocumentItemType} in types`)

          return
        }

        Vue.set(this.documentItemLocal, 'documentitemtypeid', types.documentItemTypes[allowedDocumentItemType].id)
      }

    },
    documentItemTypeName (documentItemTypeId) {
      return types.getDocumentItemTypeNameById(documentItemTypeId)
    }
  }
}
</script>