<!-- components/common/Confirm.vue -->
<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    persistent
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">{{ title }}</v-card-title>
      <v-card-text class="pt-4">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleResponse(false)">{{ cancelText }}</v-btn>
        <v-btn :color="color" @click="handleResponse(true)">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      confirmText: 'Confirm',
      cancelText: 'Cancel',
      color: 'primary'
    }
  },
  methods: {
    open(message, options = {}) {
      this.dialog = true
      this.message = message
      this.title = options.title || 'Confirm'
      this.confirmText = options.confirmText || 'Confirm'
      this.cancelText = options.cancelText || 'Cancel'
      this.color = options.color || 'primary'

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleResponse(confirmed) {
      if (this.resolve) {
        this.resolve(confirmed)
      }
      this.dialog = false
      this.message = null
      this.title = null
      this.resolve = null
      this.reject = null
    }
  }
}
</script>