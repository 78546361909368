<template>
  <v-container fluid class="fill-height d-flex align-center justify-center bg-grey-lighten-4">
    <v-row align="center" justify="center" class="ma-0 pa-0">
      <v-col cols="12" sm="8" md="6" lg="6" xl="6">
        <!-- Logo and Title Card -->
        <div class="text-center mb-4">
          <img src="/images/logo.svg" height="64" alt="Literature Hub" />
        </div>

        <!-- Login Card -->
        <v-card class="mx-auto" elevation="3">
          <v-card-text class="pa-6">
            <!-- Alert -->
            <v-alert 
              v-if="Object.keys(status).length === 0" 
              :value="wrongPasswordAlert" 
              text
              dense
              type="error"
              class="mb-4"
            >
              Invalid email or password. Please try again.
            </v-alert>

            <!-- Sign In Header -->
            <div class="d-flex align-center mb-4">
              <v-divider></v-divider>
              <span class="text-h5 grey--text text--darken-2 mx-4 font-weight-medium">Sign In</span>
              <v-divider></v-divider>
            </div>

            <!-- Login Form -->
            <v-form ref="loginForm" v-model="isFormValid" @submit.prevent="login">
              <v-text-field
                v-model="email"
                label="Email"
                outlined
                prepend-inner-icon="mdi-email"
                :rules="emailRules"
                class="mb-4"
                @keyup.enter="login"
              ></v-text-field>

              <v-text-field
                v-model="password"
                label="Password"
                outlined
                prepend-inner-icon="mdi-lock-outline"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                @click:append="showPassword = !showPassword"
                class="mb-4"
                @keyup.enter="login"
              ></v-text-field>

              <div class="d-flex align-center justify-space-between mb-4">
                <v-checkbox
                  v-model="rememberMe"
                  label="Remember me"
                  color="primary"
                  class="my-0"
                  hide-details
                ></v-checkbox>
                <v-btn
                  text
                  small
                  color="primary"
                  class="text-body-2 text-decoration-none px-0"
                  to="#"
                >
                  Forgot password?
                </v-btn>
              </div>

              <v-btn
                block
                large
                color="primary"
                :loading="loading"
                height="48"
                class="text-body-1 font-weight-bold"
                :disabled="!isFormValid"
                @click="login"
              >
                Sign In
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <!-- Version Info -->
        <div class="text-center mt-4">
          <span class="text-caption grey--text">Literature Hub: {{ config.product.version }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      wrongPasswordAlert: false,
      rememberMe: false,
      loading: false,
      isFormValid: true,
      showPassword: false,
      config: configs,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email'
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => v.length >= 4 || 'Password must be at least 4 characters'
      ]
    }
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapGetters('account', ['loggedIn'])
  },
  created() {
    // Check for stored credentials
    const storedEmail = localStorage.getItem('rememberedEmail')

    if (storedEmail) {
      this.email = storedEmail
      this.rememberMe = true
    }
  },
  methods: {
    async login() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true
      const credentials = {
        username: this.email,
        password: this.password,
        remember: this.rememberMe
      }

      try {
        await this.$store.dispatch('account/login', credentials)
        
        // Handle remember me
        if (this.rememberMe) {
          localStorage.setItem('rememberedEmail', this.email)
        } else {
          localStorage.removeItem('rememberedEmail')
        }
      } catch (error) {
        this.wrongPasswordAlert = true
        // this.$store.dispatch('app/setSnackBar', {
        //   message: 'Login failed. Please check your credentials.',
        //   color: 'error'
        // })
        
        setTimeout(() => {
          this.wrongPasswordAlert = false
        }, 3000)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>