<!-- In Filters.vue, update the template -->
<template>
  <div class="filters-container">
    <v-card flat class="filter-card">
      <div class="filter-header-main">
        <v-icon small class="mr-2">mdi-filter-variant</v-icon>
        Filters
      </div>
      
      <v-expansion-panels
        v-model="expandedPanels"
        multiple
        flat
        class="filter-panels"
      >
        <v-expansion-panel
          v-for="facet in facetList"
          :key="facet.name"
          class="filter-panel"
        >
          <v-expansion-panel-header class="filter-header">
            <div class="d-flex align-center justify-space-between w-100">
              <div class="d-flex align-center">
                <span class="filter-title">{{ renderFacetTitle(facet.name) }}</span>
                <v-chip
                  v-if="getSelectedCount(facet.name)"
                  x-small
                  outlined
                  class="selected-count ml-2"
                  color="primary"
                >
                  {{ getSelectedCount(facet.name) }}
                </v-chip>
              </div>
              <div class="filter-count">{{ facet.items.length }}</div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="filter-content">
            <!-- Search input for long lists -->
            <v-text-field
              v-if="facet.items.length > 10"
              v-model="filterSearch[facet.name]"
              dense
              hide-details
              placeholder="Search filters..."
              prepend-inner-icon="mdi-magnify"
              class="filter-search mb-2"
            ></v-text-field>

            <v-list dense class="filter-list">
              <v-list-item-group
                v-model="selected[facet.name]"
                multiple
                class="filter-group"
                @change="value => handleSelection(facet.name, value)"
              >
                <v-list-item
                  v-for="item in filteredItems(facet.name, facet.items)"
                  :key="item.key"
                  :value="item.key"
                  class="filter-item"
                >
                  <template #default="{ active }">
                    <v-list-item-action class="mr-2">
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-list-item-action>
                    
                    <v-list-item-content class="filter-item-content">
                      <v-list-item-title class="filter-item-title">
                        {{ item.key }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="filter-item-count">
                      <span class="count-number">{{ item.doc_count }}</span>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <div 
              v-if="getSelectedCount(facet.name)"
              class="filter-actions"
            >
              <v-btn
                text
                x-small
                class="clear-filter-btn"
                @click="clearFacetSelection(facet.name)"
              >
                <v-icon left x-small>mdi-close</v-icon>
                Clear {{ renderFacetTitle(facet.name).toLowerCase() }}
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'Filters',
  props: {
    facets: {
      type: Object,
      required: true
    },
    // Add this new prop
    initialSelected: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selected: {},
    expandedPanels: [0],
    initialized: false,
    filterSearch: {}
  }),
  created() {
    // Initialize selected state once
    this.initializeSelected(this.facets)
  },
  computed: {
    facetList() {
      if (!this.facets) return [];
      
      return Object.entries(this.facets)
        .filter(([_, items]) => Array.isArray(items) && items.length > 0)
        .map(([name, items]) => ({
          name,
          items: items.sort((a, b) => {
            // Handle potential undefined doc_count
            const countA = a.doc_count || 0;
            const countB = b.doc_count || 0;
            return countB - countA;
          })
        }));
    }
  },
  methods: {
    initializeSelected(facets, initialFilters = []) {
      if (this.initialized) return;
      
      const newSelected = {};
      Object.keys(facets).forEach(facetName => {
        newSelected[facetName] = [];
        
        // Get all values for this facet from initialFilters
        const facetValues = initialFilters
          .filter(([key]) => this.getFacetParam(facetName) === key)
          .map(([_, value]) => value);
        
        if (facetValues.length > 0) {
          newSelected[facetName] = facetValues;
        }
      });
      
      this.selected = newSelected;
      this.initialized = true;
    },
    // Update the renderFacetTitle method
    renderFacetTitle(name) {
      const titles = {
        'document_types': 'Document Types',
        'document_item_types': 'Item Types',
        'documents': 'Documents',
        'languages': 'Languages',
        'default_status': 'Default Status'  // Add title for definitions
      }
      
      return titles[name] || name
    },
    getSelectedCount(facetName) {
      return this.selected[facetName]?.length || 0;
    },
    handleSelection(facetName, selectedValues) {
      // Make sure we have an array, even if empty
      if (!Array.isArray(selectedValues)) {
        selectedValues = [];
      }
      
      // Update the selected state
      this.$set(this.selected, facetName, selectedValues);
      
      // Build and emit the new filters array
      const newFilters = [];
      Object.entries(this.selected).forEach(([name, values]) => {
        if (Array.isArray(values) && values.length > 0) {
          // Add each selected value as a separate filter
          values.forEach(value => {
            newFilters.push([this.getFacetParam(name), value]);
          });
        }
      });
      
      this.$emit('change', newFilters);
    },
    clearFacetSelection(facetName) {
      this.$set(this.selected, facetName, []);
      this.$emit('change', this.buildFilters());
    },
    buildFilters() {
      const filters = [];
      Object.entries(this.selected).forEach(([facetName, selectedValues]) => {
        if (Array.isArray(selectedValues) && selectedValues.length > 0) {
          selectedValues.forEach(value => {
            filters.push([this.getFacetParam(facetName), value]);
          });
        }
      });
      return filters;
    },
    getFacetParam(facetName) {
      const paramMap = {
        'document_types': 'doc_type',
        'document_item_types': 'doc_item_type',
        'documents': 'document',
        'languages': 'language',
        'default_status': 'is_default'  // Add mapping for definitions
      }
      return paramMap[facetName] || facetName
    },

    // Add this new method for filtering items
    filteredItems(facetName, items) {
      const searchTerm = (this.filterSearch[facetName] || '').toLowerCase();
      if (!searchTerm) return items;
      
      return items.filter((item) => 
        item.key.toLowerCase().includes(searchTerm)
      );
    }
  },
  watch: {
    facets: {
      handler(newFacets) {
        if (!this.initialized) {
          this.initializeSelected(newFacets, this.initialSelected);
        }
      },
      immediate: true
    },
    initialSelected: {
      handler(newSelected) {
        // Reset initialization when initial selection changes
        this.initialized = false;
        this.initializeSelected(this.facets, newSelected);
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.filters-container {
  position: sticky;
  top: 16px;
  max-height: calc(100vh - 32px);
  overflow-y: auto;
}

.filter-card {
  border-radius: 8px;
  background: white !important;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1) !important;
}

.filter-header-main {
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0,0,0,0.87);
  border-bottom: 1px solid rgba(0,0,0,0.08);
  display: flex;
  align-items: center;
}

.filter-panels {
  background: transparent !important;
}

.filter-panel {
  background: transparent !important;
  border-bottom: 1px solid rgba(0,0,0,0.08);
}

.filter-panel:last-child {
  border-bottom: none;
}

.filter-header {
  min-height: 40px !important;
  padding: 0 16px !important;
}

.filter-header::before {
  display: none !important;
}

.filter-title {
  font-size: 13px;
  font-weight: 500;
  color: rgba(0,0,0,0.87);
}

.filter-count {
  font-size: 12px;
  color: rgba(0,0,0,0.54);
}

.selected-count {
  height: 18px !important;
  font-size: 11px !important;
}

.filter-content {
  padding: 8px !important;
}

.filter-search {
  margin: 0 4px;
}

.filter-search :deep(.v-input__control) {
  min-height: 32px;
}

.filter-list {
  max-height: 300px;
  overflow-y: auto;
}

.filter-item {
  border-radius: 4px !important;
  min-height: 32px !important;
}

.filter-item:hover {
  background: rgba(0,0,0,0.04);
}

.filter-item-content {
  padding: 4px 0;
}

.filter-item-title {
  font-size: 13px !important;
  line-height: 1.3 !important;
  color: rgba(0,0,0,0.87);
}

.filter-item-count {
  min-width: 32px;
  text-align: right;
}

.count-number {
  font-size: 12px;
  color: rgba(0,0,0,0.54);
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(0,0,0,0.04);
}

.filter-actions {
  padding: 4px 8px;
  margin-top: 4px;
  border-top: 1px solid rgba(0,0,0,0.08);
}

.clear-filter-btn {
  width: 100%;
  text-transform: none !important;
  letter-spacing: 0 !important;
  color: rgba(0,0,0,0.6) !important;
}

/* Scrollbar styling */
.filter-list::-webkit-scrollbar {
  width: 4px;
}

.filter-list::-webkit-scrollbar-track {
  background: transparent;
}

.filter-list::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2);
  border-radius: 2px;
}
</style>