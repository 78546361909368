<template>
  <v-card class="mb-2" tile flat>
    <div class="d-flex">
      <div align="center">
        <ImageManager 
          v-if="isImageEnabled()"
          :id="document.documentid"
          :connection-type-id="connectionType.documents"
        />
      </div>
      <div>
        <v-card-text class="text-caption pb-0 mb-0">
          <router-link :to="{ name: 'documents_landing', query: { tab: document.documenttypeid.name } }">{{ document.documenttypeid.name }}s</router-link>
        </v-card-text>
        <v-card-title class="mt-0 pt-0 pb-0 mb-0">{{ document.englishtitle }}</v-card-title>
        <v-card-text v-if="documentAuthors && documentAuthors.length" class="text-caption">
          <span v-for="(a, i) in documentAuthors" :key="a.documentauthorid">
            <router-link :to="{ name: 'person_home', params: { personid: a.personid.personid }}">{{ a.personid.englishname }}</router-link>
            <span v-if="i+1 < documentAuthors.length">, </span>
          </span>
        </v-card-text>
        <v-card-text v-else></v-card-text>
      </div>
      <v-spacer></v-spacer>
      <DocumentMenu v-if="allowEdit(document.documenttypeid.name)" class="pa-2"/>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import ImageManager from '@/components/ImageManager'
// import DocumentItemsPrevNext from '@/components/documentItems/DocumentItemsPrevNext'
import DocumentMenu from '@/components/documents/DocumentMenu'
import configs from '@/configs'

export default {
  name: 'DocumentHeading',
  components: {
    ImageManager,
    // DocumentItemsPrevNext,
    DocumentMenu
  },
  data: () => ({
    loading: true,
    connectionType: configs.connectionProperties.types
  }),
  computed: {
    ...mapState('documents', ['dataStructure', 'document', 'documentTranslations', 'documentAuthors']),
    ...mapGetters('documents', ['isImageEnabled', 'getDocumentDataStructure']),
    ...mapGetters('account', ['allowEdit'])
  }
}
</script>